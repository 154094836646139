$primary: #248ab6;

.comparatorContainer {
  left: 5%;
  height: 0;
  width: 90%;
  z-index: 300;
  transition: bottom 250ms ease;
}

.tabContainer {
  top: 0;
  left: 50%;
  transform: translate(-50%, -100%);
}

.tab {
  height: 40px;
  border: none;
  outline: none;
  &:focus {
    outline: none;
    border: none;
  }
}

.comparatorText {
  height: 100%;
  box-shadow: 0 -3px 8px -3px $primary;
  font-size: 0.9rem;
}

.leftHill,
.rightHill {
  width: 50px;
  box-shadow: 0px -2px 9px -4px $primary;
}

.leftHill {
  height: 100%;
  border-top-left-radius: 100%;
  margin-right: -1px;
}

.rightHill {
  height: 100%;
  border-top-right-radius: 100%;
  margin-left: -1px;
}

.comfortBar {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 0.5rem;
}
