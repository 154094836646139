.backIcon {
  font-size: 0.5rem;
  color: white;
}

.textSearchInput {
  outline: none;
}

.textQueryContainer {
  flex-basis: 40%;
  height: 34px;
}

.searchButton {
  transition: transform 100ms ease;
  border: none;
  outline: none;
  text-decoration: none;
  padding: 0.6rem;
  margin-right: 0.3rem;
  background-color: transparent;
  color: white;
  background-color: black;
  clip-path: circle();
  // border-radius: 100%;
  // height: 30px;
  // width: 30px;
}

.searchButton:active,
.searchButton:focus {
  outline: 0px solid transparent !important;
  border: 0px solid transparent !important;
  transform: scale(1.1);
}

@media (min-width: 1400px) {
  .softwareCatalogContainer {
    max-width: 550px !important;
  }
}
@media (min-width: 1200px) and (max-width: 1399px) {
  .softwareCatalogContainer {
    max-width: 500px !important;
  }
}
@media (min-width: 992px) and (max-width: 1199px) {
  .softwareCatalogContainer {
    max-width: 450px !important;
  }
}
@media (min-width: 768px) and (max-width: 991px) {
  .softwareCatalogContainer {
    max-width: 400px !important;
  }
}
@media (min-width: 576px) and (max-width: 767px) {
  .softwareCatalogContainer {
    max-width: 350px !important;
  }
}
@media (min-width: 0px) and (max-width: 575px) {
  .softwareCatalogContainer {
    max-width: 90vw !important;
  }
}

.softwareHeader {
  font-size: 6rem;
}