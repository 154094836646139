.searchIconBtn {
    height: fit-content;
}

.searchIcon {
    padding: 0.6rem;
    height: 2.0rem !important;
    width: 2.0rem !important;
    border-radius: 50%;
    vertical-align: middle;
}

.searchFieldBubblesWrapper {
    box-shadow: 0 4px 6px -3px grey;
}

.closeWindowContainer {
    transition: transform 250ms ease-in;
}

.closeWindowContainer:hover,
.closeWindowContainer:active {
    text-decoration: none;
    transform: translateY(-1px);
}