/* Provide sufficient contrast against white background */

$primary: #248ab6;
$success: #248ab6;
// $danger: darkred;
$border-radius: 5px;
$light: #f1f1f1;
$dark: #1d1d1d;
$darker: #111;

@import '../node_modules/bootstrap/scss/bootstrap.scss';

html {
  font-size: 14px;
}
@media (min-width: 768px) {
  html {
    font-size: 16px;
    overflow-x: hidden;
  }
}

a {
  color: #0366d6;
}

code {
  color: #E01A76;
}

.box-shadow {
  box-shadow: 0 .25rem .75rem rgba(0, 0, 0, .05);
}

.rc-slider-track {
  background-color: $primary;
  top: 3px;
  height: 8px;
}

.rc-slider-handle {
  border-radius: 0;
  border-width: 1px;
  border-color: $gray-400;
  width: 1rem;
  height: 1rem;
}

.rounded-bl {
  border-bottom-left-radius: $border-radius;
}

// Additional Backgrounds
.bg-white {
  background-color: $white;
}

.bg-light-grey {
  background-color: $gray-600;
}

.bg-grey {
  background-color: $gray-300;
}

.bg-darker {
  background-color: $darker;
}

.bg-black {
  background-color: $black;
}

.bg-checkbox-filter {
  background-color: $gray-800;
}

.bg-checkbox-comparator {
  background-color: $gray-200;
}

.w-fit-content {
  width: fit-content;
}

.flex-grow {
  flex-grow: 1;
}

.pointer {
  cursor: pointer;
}

@keyframes overlay-show {
  from {
    visibility: hidden;
    opacity: 0;
  }

  to {
    visibility: visible;
    left: 0.7;
  }
}

@keyframes overlay-hide {
  from {
    visibility: visible;
    opacity: 0.7;
  }

  to {
    visibility: hidden;
    opacity: 0;
  }
}

.show {
  animation: overlay-show 300ms forwards;
}

.hide {
  animation: overlay-hide 300ms forwards;
}

.zi-200 {
  z-index: 200;
}

.checkmark {
  position: absolute;
  padding: 0.53rem;
  top: 40%;
  left: -0.3px;
  transform: translateY(-50%);
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #6a6a6a;
  margin-right: 1px;
}

@include media-breakpoint-up(lg) {
  .w-lg-66 {
    width: 66.66667% !important;
  }
}

.disabled {
  cursor: not-allowed !important;
}

.h5 {
  font-size: 1.25rem !important;
}

.hover-primary:hover {
  color: $primary !important;
}

h1, h2, h3 {
  font-family: "Bebas Neue";
}

* {
  font-family: "Roboto";
}

.very-big {
  font-size: 6rem;
}