@media (min-width: 1200px) and (max-width: 1399px) {
  .dataPoints {
    columns: 1;
    flex-basis: 58%;
  }
}

@media (min-width: 1400px) {
  .dataPoints {
    columns: 2;
    gap: 1rem;
    flex-basis: 66%;
  }
}

@media (min-width: 1200px) and (max-width: 1399px) {
  .cardVisualsContainer {
    flex-basis: 42%;
  }
}

@media (min-width: 1400px) {
  .cardVisualsContainer {
    flex-basis: 34%;
  }
}

.checkmark {
  position: absolute;
  padding: 0.53rem;
  top: 40%;
  left: -0.3px;
  transform: translateY(-50%);
  border-radius: 3px;
  cursor: pointer;
  border: 1px solid #6a6a6a;
  margin-right: 1px;
}

.leaveContainer {
  justify-content: space-evenly;
}

.leaveContainer > * {
  flex-basis: 40%;
}

.learnMore {
  color: #248ab6 !important;
}

.learnMore:hover {
  color: white !important;
}