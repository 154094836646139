.textSearchInput {
    outline: none;
}

.searchBubblesContainer {
    overflow-y: scroll;
}

.searchBubble {
    box-shadow: 0px 4px 3px -3px grey;
}

.textQueryContainer {
    flex-basis: 40%;
    height: 34px;
}

.searchBubblesContainer::-webkit-scrollbar-track {
    padding: 1px 0;
}

.searchBubblesContainer::-webkit-scrollbar {
    width: 4px;
    margin-right: 1px;
}

.searchBubblesContainer::-webkit-scrollbar-thumb {
    border-radius: 10px;
    box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.1);
    background-color: #eee;
}
