.overlay {
  background-color: rgba(0, 0, 0, 0.8);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  transition: opacity 500ms ease;
}

.overlayButton {
  height: 100%;
  width: 100%;
  outline: none;
  border: none;
  background-color: transparent;
  &:active,
  &:focus {
      outline: none;
      border: none;
  }
}