.smallHeader {
  font-weight: 400;
  font-size: 0.75rem;
}

.centerText {
  text-align: center;
}

.mb25 {
  margin-bottom: 25px !important;
}